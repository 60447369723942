
body, html {
  font-family: Arial, sans-serif;
}

.MuiTableHead-root {
  background-color: #EBEBEB;
}

.MuiTableFooter-root {
  background-color: #D2E1F5;
  font-weight: bold;
}

.MuiDataGrid-columnHeader {
  background-color: #EBEBEB;
}
/* 
input:invalid {
  background-color: #FFEDED;
  border: 1px solid #FF8E8E;
} */
