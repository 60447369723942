.google-auth-container {
  margin:auto;
  width: 250px;
  top:45%;
  height: 200px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 35px;
  position: relative;
  text-align: center;
  padding-top: 20px;
}
.jig_neste-logo {
  position: relative;
  max-width: 500px;
  padding-top: 25px;
  padding-left: 25px;
  margin-top: auto;
  margin-right: auto;
}
.google-auth-button {
  margin-top: -10px !important;
  margin-left: 10px !important;
  background-color: #62bb21 !important;
}
#jig_google-logo {
  margin-top: 20px;
}
.jig_landing-page {
  background-image: url("../../static/landingpage3.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.jig_login-loading {
  margin: auto;
  padding-top: 30px;
}
